// Leave endDate a empty string to show as working their now
export default [
  {
    jobTitle: 'Frontend Developer Intern',
    company: 'Stonewall Group',
    companyLink: 'https://stonewallgroup.com/',
    location: 'Marietta, OH',
    startDate: '2019-05-01T00:00:00',
    endDate: '2019-08-01T00:00:00',
    summaryOfTasks: [
      'Developed JavaScript animations for <a href="https://westvirginia.gov/industries/automotive/#1199"> westvirginia.gov</a>',
      'Worked on development of <a href="https://wvforestry.com">wvforestry.com</a>',
      'Developed several bash scripts to accelerate developer work flow',
      'Setup Continuous Deployment pipeline for WordPress sites',
    ],
  },
  {
    jobTitle: 'Computer Science Tutor',
    company: 'West Virginia University at Parkersburg',
    companyLink: 'https://wvup.edu/',
    location: 'Parkersburg, WV',
    startDate: '2017-01-01T00:00:00',
    endDate: '2020-06-01T00:00:00',
    summaryOfTasks: [
      'Helped students on struggling concepts and basics of programming',
      'Main technologies taught: Java, .Net Core, C, Java, C#, HTML, CSS, JavaScript',
    ],
  },
]
