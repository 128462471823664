import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import SEO from '../components/seo'
import skills from '../data/skills'
import SpaceBetweenText from '../ui/SpaceBetweenText'
import education from '../data/education'
import work from '../data/work'
import { toMonthYearString } from '../utils'

const AboutMeLayout = styled.div`
  margin: 5em 0;
  display: grid;
  grid-gap: 5em;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-content: space-between;

  .profile-pic {
    border-radius: 50%;
    max-width: 500px;
    width: 30%;
    min-width: 250px;
    height: auto;
    box-shadow: 0px 0px 0px 5px ${props => props.theme.colors.black},
      0px 0px 0px 10px ${props => props.theme.colors.white};
  }
  .details {
    min-width: 30ch;
    > h3 {
      margin-bottom: 1em;
    }
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    justify-content: center;

    .profile-pic {
      margin: auto;
    }
  }
`

const SkillList = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  list-style: none;
  font-size: 1.2rem;
  margin: 0;
  padding: 0;

  li {
    background: ${p => p.theme.colors.grayDarkest};
    color: ${p => p.theme.colors.white};
    padding: 1em;
    border-radius: 5px;
    margin-right: 1em;
    margin-bottom: 1em;
  }
`

const WorkItem = ({ work, className, key }) => (
  <article className={className} key={key}>
    <h3>{work.jobTitle}</h3>
    <h4>
      <span>
        <a href={work.companyLink}>{work.company}</a>
      </span>
      <span>{work.location}</span>
      <span>
        {toMonthYearString(new Date(work.startDate))} -{' '}
        {work.endDate ? toMonthYearString(new Date(work.endDate)) : 'Now'}
      </span>
    </h4>
    <ul>
      {work.summaryOfTasks.map(s => (
        <li dangerouslySetInnerHTML={{ __html: s }} key={s}></li>
      ))}
    </ul>
  </article>
)

const StyledWorkItem = styled(WorkItem)`
  > h4 {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
`

const IndexPage = ({
  data: {
    fileName: { childImageSharp },
  },
  location,
}) => (
  <>
    <SEO
      title="Resume"
      description="Levi Butcher's Resume"
      path={location.pathname}
    />
    <h1>Resume</h1>
    <section>
      <h2>About Me</h2>
      <AboutMeLayout>
        <Img
          fluid={childImageSharp.fluid}
          alt="Me, Levi Butcher"
          className="profile-pic"
        />
        <div className="details">
          <h3>Hello there, My name is Levi Butcher.</h3>
          <p>
            I'm from a small town in West Virginia. I enjoy solving complex
            problems with software solutions. Besides writing code, I also like
            video games, youtube, and a good joke.
          </p>
          <p>
            I'm also a big fan of podcasts. I frequently listen to tech podcasts
            such as Coding Blocks, Syntax, and CoRecursive.
          </p>
          <p>
            You can contact me at{' '}
            <a href="mailto:levibutcher355@gmail.com?Subject=Hello Levi">
              levibutcher355@gmail.com
            </a>{' '}
            or on <a href="https://twitter.com/LeviButcher">Twitter</a>,{' '}
            <a href="https://github.com/LeviButcher">Github</a>, or{' '}
            <a href="https://www.linkedin.com/in/levi-butcher-24938b178/">
              LinkedIn
            </a>
          </p>
        </div>
      </AboutMeLayout>
    </section>
    <section>
      <h2>Skills</h2>
      <SkillList>
        {skills.map(s => (
          <li data-rank={s.rank} key={s.name}>
            {s.name}
          </li>
        ))}
      </SkillList>
    </section>
    <section>
      <h2>Education</h2>
      {education.map(x => (
        <div key={x.degree}>
          <SpaceBetweenText>
            <span>{x.degree}</span> <span>{x.GPA}</span>
          </SpaceBetweenText>
          <p>{x.location}</p>
        </div>
      ))}
    </section>
    <section>
      <h2>Work Experience</h2>
      {work.map(x => (
        <StyledWorkItem work={x} key={x.jobTitle + x.company} />
      ))}
      <p>
        Also, check out my <Link to="/projects">projects page</Link>
      </p>
    </section>
    <section>
      <h2 style={{ wordBreak: 'break-word' }}>
        Honors, Certifications, Accomplishments
      </h2>
      <ul>
        <li>ACM Chapter President of WVUP Computer Club</li>
        <li>
          Microsoft Technology Associate: Software Development Fundamentals
          Certification
        </li>
        <li>The National Society of Leadership and Success Member</li>
        <li>Phi Theta Kappa Member</li>
        <li>Magna Cum Laude</li>
      </ul>
    </section>
  </>
)
export const query = graphql`
  {
    fileName: file(relativePath: { eq: "levibutcher.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
