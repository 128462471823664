import styled from 'styled-components'

const SpaceBetweenText = styled.h3`
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  & > * {
    margin: 0.5rem;
    margin-left: 0;
  }
`

export default SpaceBetweenText
