export default [
  {
    degree: "Master's in Computer Science",
    GPA: 'Attending Fall 2020',
    location: 'West Virginia University',
  },
  {
    degree: "Bachelor's in Software Engineering",
    GPA: 'GPA 3.96',
    location: 'West Virginia University at Parkersburg',
  },
  {
    degree: "Associate's in Computer Science",
    GPA: 'GPA 3.96',
    location: 'West Virginia University at Parkersburg',
  },
]
