export default [
  { name: 'C# / .Net Core', rank: 3, group: ['Backend'] },
  { name: 'Git', rank: 3, group: ['Frontend', 'Backend'] },
  { name: 'Java', rank: 3, group: ['Backend'] },
  { name: 'Javascript / Node.js', rank: 4, group: ['Frontend', 'Backend'] },
  { name: 'Functional Programming', rank: 2, group: ['Frontend', 'Backend'] },
  {
    name: 'Object Oriented Programming',
    rank: 3,
    group: ['Frontend', 'Backend'],
  },
  { name: 'C++ / C', rank: 2, group: ['Backend'] },
  { name: 'Linux', rank: 3, group: ['Backend'] },
  { name: 'SQL Server', rank: 2, group: ['Backend'] },
  { name: 'MongoDb', rank: 2, group: ['Backend'] },
  { name: 'CSS', rank: 3, group: ['Frontend'] },
  { name: 'Responsive Design', rank: 1, group: ['Frontend'] },
  { name: 'Docker / Docker Compose', rank: 3, group: ['Backend'] },
  { name: 'React', rank: 4, group: ['Frontend'] },
  { name: 'Rust', rank: 1, group: ['Backend'] },
  { name: 'Flow', rank: 1, group: ['Frontend'] },
  { name: 'CI / CD' },
  { name: 'GraphQL' },
  { name: 'Scala' },
  { name: 'Haskell' },
  { name: 'PostgresSQL' },
]
